import { theme } from 'antd';
import { css } from '@emotion/css';

const orderNumberWidth = '100px';
const dateWidth = '160px';

export const useRequestRendererClassNames = () => {
  const { token } = theme.useToken();

  const requestGrid = css`
    display: grid;
    grid-template-columns: ${orderNumberWidth} ${dateWidth} auto;
    grid-template-areas: 'ordernumber date state';
    gap: ${token.padding}px;
    width: 100%;
  `;

  const subheaderClassName = css`
    color: ${token.colorTextDisabled};
    font-size: ${token.fontSizeSM}px;
    font-weight: bold;
    text-transform: uppercase;
  `;

  const orderNumberCellClassName = css`
    grid-area: ordernumber;
  `;
  const stateCellClassName = css`
    grid-area: state;
  `;
  const dateCellClassName = css`
    grid-area: date;
  `;

  return {
    requestGrid,
    subheaderClassName,
    orderNumberCellClassName,
    stateCellClassName,
    dateCellClassName,
  };
};
