import React, { useEffect, useState } from 'react';
import { Avatar, Button, Flex, Input, List, theme, Tooltip } from 'antd';
import { css, cx } from '@emotion/css';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { BEFUNDE_LIST } from '../../utils/elementIds.ts';
import { Link } from 'react-router-dom';
import { useSearchLabResults } from '../../api/lab-result.queries.ts';
import { useDebounce } from 'use-debounce';
import { patientFullName } from '../orderresult/PatientInfo.tsx';
import type { LabResultsSearchResult } from '../../generated/openapi/befunde-api';

const DEFAULT_PAGE_SIZE = 3;

const useBefundeListClassNames = () => {
  const { token } = theme.useToken();

  return {
    layout: css`
      height: 100%;
      width: 100%;
      gap: ${token.paddingLG}px;
      padding-bottom: ${token.paddingLG}px;

      @media (min-width: ${token.screenLG}px) {
        width: 350px;
      }

      @media (min-width: ${token.screenXXL}px) {
        width: 400px;
      }
    `,
    befundListWrapper: css`
      height: 100%;
      overflow-y: auto;
      width: 100%;
      border-right: 1px solid ${token.colorBorder};
      padding-right: ${token.paddingXS}px;

      @media (max-width: ${token.screenLG}px) {
        border-right: none;
        padding-right: 0;
      }
    `,
    befundListItem: (selected: boolean) =>
      cx(
        css`
          border-radius: ${token.borderRadius}px;
          border-block-end: none !important;
          padding-left: ${token.paddingXS}px !important;
          padding-right: ${token.paddingXS}px !important;

          // :hover {
          //   background-color: ${token.controlItemBgActive};
          // }
        `,
        {
          [css`
            background-color: ${token.controlItemBgActiveHover};
          `]: selected,
        }
      ),
  };
};

export const BefundeList: React.FC<{ selectedId: string | undefined }> = ({ selectedId }) => {
  const styles = useBefundeListClassNames();
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [debouncedSearch] = useDebounce(search, 500);
  const [pageable, setPageable] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: labResults, isLoading: labResultsLoading } = useSearchLabResults({
    page: pageable.page,
    pageSize: pageable.pageSize,
    search: debouncedSearch,
  });
  const [loadedItems, setLoadedItems] = useState<LabResultsSearchResult[]>([]);

  useEffect(() => {
    if (!labResultsLoading) {
      setLoadedItems(prev => (labResults ? [...prev, ...labResults.items] : []));
    }
  }, [labResults, setLoadedItems, labResultsLoading]);

  return (
    <Flex vertical className={styles.layout}>
      <Input
        allowClear
        autoFocus
        size="large"
        placeholder="Name, SVNR, Auftragsnummer ..."
        value={search || ''}
        onChange={e => {
          setPageable(current => ({ ...current, page: 1 })); // reset page
          setSearch(e.target.value);
        }}
        prefix={<SearchOutlined />}
        suffix={
          <Tooltip title="Suche Befunde nach Name, SVNR, Auftragsnummer">
            <InfoCircleOutlined />
          </Tooltip>
        }
      />
      <div id={BEFUNDE_LIST} className={styles.befundListWrapper}>
        <List
          dataSource={loadedItems}
          renderItem={item => (
            <Link to={`/${item.id}`}>
              <List.Item key={item.id} className={styles.befundListItem(item.id === selectedId)}>
                <List.Item.Meta
                  avatar={
                    <Avatar>{item.lastName.charAt(0).toUpperCase() + item.firstName.charAt(0).toUpperCase()}</Avatar>
                  }
                  title={<strong>{patientFullName(item)}</strong>}
                  description={
                    <span>
                      SVNR {item.svnr}, Auftrag {item.orderNumber}
                    </span>
                  }
                />
              </List.Item>
            </Link>
          )}
        />
        {labResults && labResults.items.length == DEFAULT_PAGE_SIZE ? (
          <Button type="link" onClick={() => setPageable(prev => ({ ...prev, page: prev.page + 1 }))}>
            Weitere Befunde laden
          </Button>
        ) : null}
      </div>
    </Flex>
  );
};
