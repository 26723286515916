import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Filter } from './FilterTypes.ts';
import { Flex, Input, Tabs, theme } from 'antd';
import { css } from '@emotion/css';
import { useKeyAction } from '../../../hooks/useKeyAction.ts';

const useObservationResultFilterClassNames = () => {
  const { token } = theme.useToken();

  return {
    layoutClassName: css`
      gap: ${token.padding}px;
      width: 100%;
      justify-items: right;
    `,
    inputClassName: css`
      margin: 8px 8px 0 0;
    `,
    tabsClassName: css`
      flex-grow: 1;
    `,
  };
};

export const ObservationResultFilter: React.FC<{
  value: Filter;
  onChange: Dispatch<SetStateAction<Filter>>;
}> = ({ value, onChange }) => {
  const [filterTokens, setFilterTokens] = useState(value.tokens.join(' '));
  const [focused, setFocused] = useState(false);
  const [key, setKey] = useState<string>(value.pathological === null ? 'a' : value.pathological ? 'p' : 'n');

  const { layoutClassName, inputClassName, tabsClassName } = useObservationResultFilterClassNames();

  useEffect(() => {
    onChange(prev => ({
      ...prev,
      tokens: filterTokens
        .trim()
        .split(' ')
        .filter(t => !!t)
        .map(t => t.toLowerCase()),
    }));
  }, [onChange, filterTokens]);

  useEffect(() => {
    onChange(prevState => ({
      ...prevState,
      pathological: key === 'a' ? null : key === 'p',
    }));
  }, [onChange, key]);

  const action = useMemo(() => {
    if (focused) {
      return () => {
        setFilterTokens('');
      };
    } else {
      return null;
    }
  }, [focused, setFilterTokens]);

  useKeyAction('Escape', action);

  return (
    <Flex className={layoutClassName}>
      <Tabs
        className={tabsClassName}
        size="small"
        activeKey={key}
        onChange={setKey}
        items={[
          { key: 'a', label: 'Alle' },
          { key: 'p', label: 'pathologisch' },
          { key: 'n', label: 'nicht pathologisch' },
        ]}
      />
      <div className={inputClassName}>
        <Input
          placeholder={'Filter'}
          value={filterTokens}
          onChange={e => setFilterTokens(e.target.value)}
          allowClear
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </div>
    </Flex>
  );
};
