import React from 'react';
import { cx } from '@emotion/css';
import { useRequestRendererClassNames } from './useRequestRendererClassNames.ts';
import { formatDateTimeString } from '../../../utils/datetime.ts';
import { LabResultObservationRequestStatusEnum, LabResultResponse } from '../../../generated/openapi/befunde-api';

const translateLabResultState = (value: LabResultObservationRequestStatusEnum) => {
  switch (value) {
    case 'IN_PROGRESS':
      return 'Befund in Arbeit';
    case 'PARTIAL_RESULT':
      return 'Teilbefund';
    case 'END_RESULT':
      return 'Endergebnis';
    case 'ORDER_RECEIVED':
      return 'Auftrag empfangen';
    case 'SPECIMEN_RECEIVED':
      return 'Probe empfangen';
  }
  return value;
};

export const RequestRenderer: React.FC<{ result: LabResultResponse }> = ({ result }) => {
  const { requestGrid, subheaderClassName, orderNumberCellClassName, stateCellClassName, dateCellClassName } =
    useRequestRendererClassNames();

  return (
    <div>
      <div className={requestGrid}>
        <div className={cx(orderNumberCellClassName, subheaderClassName)}>Auftragsnr.</div>
        <div className={cx(dateCellClassName, subheaderClassName)}>Eingangsdatum</div>
        <div className={cx(stateCellClassName, subheaderClassName)}>Status</div>
      </div>
      <div className={requestGrid}>
        <div className={orderNumberCellClassName}>{result.orderNumber}</div>
        <div className={dateCellClassName}>{formatDateTimeString(result.observationRequest.orderEntryDate)}</div>
        <div className={stateCellClassName}>{translateLabResultState(result.observationRequest.status)}</div>
      </div>
    </div>
  );
};
